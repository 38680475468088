import { createAction, props } from '@ngrx/store';
import { ScheduleInfo, ScheduleInfoOptimiserObjective } from '@features/schedules-new/schedules.models';
import { OverrideCheckbox } from '@features/schedules-new/schedule-settings/schedule-settings.component';
import { ScheduleLinearity } from '@features/schedules-new/flow/flow.model';
import { ScheduleSettingsCap } from '@features/schedules-new/schedule-settings/schedule-settings.models';

export const apiScheduleFetchedOptimiserObjectiveSuccess = createAction(
  '[Schedules API] Schedule fetched optimiser objective',
  props<{ scheduleOptimiserObjective: ScheduleInfoOptimiserObjective[] }>()
);

export const apiScheduleFetchedOptimiserObjectiveFailed = createAction(
  '[Schedules API] Schedule fetched optimiser objective failed'
);

export const scheduleSettingsLinearityChanged = createAction(
  '[Schedule Settings Page] Linearity changed',
  props<{ scheduleLinearity: ScheduleLinearity }>()
);

export const apiScheduleSettingsLinearityChangedSuccess = createAction(
  '[Schedule Settings API] Linearity changed success',
  props<{ scheduleLinearity: ScheduleLinearity }>()
);

export const apiScheduleSettingsLinearityChangedFailed = createAction(
  '[Schedule Settings API] Linearity changed failed'
);

export const scheduleSettingsCapStarted = createAction(
  '[Schedule Settings CAP screen] Schedule Settings CAP screen started'
);
export const scheduleSettingsCapAPIFetchingSuccess = createAction(
  '[Schedule Settings Cap API] Schedule Settings Cap fetched successfully using API',
  props<{ scheduleSettingsCap: ScheduleSettingsCap[] }>()
);
export const scheduleSettingsCapAPIFetchingFailed = createAction(
  '[Schedule Settings Cap API] Schedule Settings Cap fetching failed using API'
);

export const scheduleSettingsSectorCapsAdded = createAction(
  '[Schedule Settings Page] Sector caps added',
  props<{ scheduleSettingsCap: ScheduleSettingsCap }>()
);

export const apiScheduleSettingsSectorCapsAddedSuccess = createAction(
  '[Schedule Settings API] Sector caps added success',
  props<{ scheduleSettingsCap: ScheduleSettingsCap }>()
);

export const apiScheduleSettingsSectorCapsAddedFailed = createAction(
  '[Schedule Settings API] Sector caps added failed'
);

export const scheduleSettingsSectorCapsUpdated = createAction(
  '[Schedule Settings Page] Sector caps updated',
  props<{ scheduleSettingsCap: ScheduleSettingsCap }>()
);

export const apiScheduleSettingsSectorCapsUpdatedSuccess = createAction(
  '[Schedule Settings API] Sector caps updated success',
  props<{ scheduleSettingsCap: ScheduleSettingsCap }>()
);

export const apiScheduleSettingsSectorCapsUpdatedFailed = createAction(
  '[Schedule Settings API] Sector caps updated failed'
);

export const scheduleSettingsSectorCapsDeleted = createAction(
  '[Schedule Settings Page] Sector caps Deleted',
  props<{ sectorCapId: string }>()
);

export const apiScheduleSettingsSectorCapsDeletedSuccess = createAction(
  '[Schedule Settings API] Sector caps Deleted success'
);

export const apiScheduleSettingsSectorCapsDeletedFailed = createAction(
  '[Schedule Settings API] Sector caps Deleted failed'
);

export const scheduleSettingsSlotProfileChanged = createAction(
  '[Schedule Settings Page] Slot profile changed',
  props<{ value: number; message: string }>()
);

export const scheduleSettingsSlotOverridesCheckboxChanged = createAction(
  '[Schedule Settings Page] Slot overrides checkbox changed',
  props<{ value: boolean; checkboxType: OverrideCheckbox; message: string }>()
);

export const scheduleSettingsHistoricSlotsRetimingChanged = createAction(
  '[Schedule Settings Page] Historic Slots Retiming changed',
  props<{ value: number; message: string }>()
);

export const scheduleSettingsProgressionMinsChanged = createAction(
  '[Schedule Settings Page] Progression Mins changed',
  props<{ value: number; message: string }>()
);

export const scheduleSettingsOptimiserRuntimeMinutesChanged = createAction(
  '[Schedule Settings Page] Optimiser Runtime Minutes changed',
  props<{ value: number; message: string }>()
);

export const scheduleSettingsOptimiserObjectiveChanged = createAction(
  '[Schedule Settings Page] Optimiser Objective changed',
  props<{ value: string; message: string }>()
);

export const scheduleSettingsOptimiserBranchChanged = createAction(
  '[Schedule Settings Page] Optimiser Branch changed',
  props<{ value: string; message: string }>()
);

export const apiScheduleSettingsUpdateScheduleSuccess = createAction(
  '[Schedule Settings API] Update schedule info success',
  props<{ schedule: ScheduleInfo }>()
);

export const apiScheduleSettingsUpdateScheduleFailed = createAction(
  '[Schedule Settings API] Update schedule info failed'
);
